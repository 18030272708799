import useMe from "../scenes/auth/hooks/useMe";
import { useCallback, useMemo } from "react";
import { Address } from "@whoppah/apollo/schema";

const NO_ADDRESSES: Address[] = [];

const useMerchant = () => {
  const { data, loading, refetch } = useMe();

  const me = data?.me;
  const merchant = me?.merchants?.[0];

  const id = merchant?.id;
  const phone = merchant?.phone;
  const rating = merchant?.rating ?? null;
  const numberOfAds = merchant?.number_of_ads;

  const image = merchant?.image?.url;

  const addresses = useMemo(() => {
    return data?.me?.merchants?.[0]?.addresses ?? NO_ADDRESSES;
  }, [data?.me?.merchants]);

  const hasAddress = !!addresses.length;

  const isAddressValid = useCallback((address: Address) => {
    return (
      !!address.country &&
      !!address.line1 &&
      !!address.postal_code &&
      !!address.city
    );
  }, []);

  const hasFavorites = !!merchant?.number_of_favorites;

  const isInfoMissing = useMemo(() => {
    if (!me) {
      return false;
    }

    const address = merchant?.addresses[0];

    return (
      !me.given_name ||
      !me.family_name ||
      !me.merchants[0]?.phone ||
      !me.email ||
      !address?.country ||
      !address?.line1 ||
      !address?.postal_code ||
      !address?.city ||
      !me.dob
    );
  }, [me, merchant?.addresses]);

  const isPaymentInfoMissing = useMemo(() => {
    if (!me) {
      return false;
    }

    if (!merchant) {
      return false;
    }

    return (
      !merchant.bank_account?.account_number ||
      !merchant.bank_account.account_holder_name
    );
  }, [me, merchant]);

  const hasAds = !!me?.merchants[0]?.number_of_ads;

  const getIsVerified = useCallback(
    (merchantArg: { expert_seller?: boolean | null }) => {
      const merchantActual = merchantArg ?? merchant;
      return merchantActual.expert_seller === true;
    },
    [merchant]
  );

  return {
    id,
    loading,
    merchant,
    image,
    refetch,
    phone,
    rating,
    addresses,
    hasAddress,
    locale: me?.locale,
    isAddressValid,
    hasFavorites,
    isInfoMissing,
    isPaymentInfoMissing,
    hasAds,
    getIsVerified,
    numberOfAds,
  };
};

export default useMerchant;
