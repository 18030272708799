import { cn } from "@/common/utils/css.utils";
import { LinkButton } from "@/ui/next/LinkButton";
import { Heading } from "@/ui/cva/Heading";
import { useTranslation } from "next-i18next";
import { Spark as SparkLogo } from "@/assets/icons/Logo";
import { analyticsService } from "@/common/services/analytics/analytics.service";
import { useRouter } from "next/router";
import Image from "@/components/common/Image";
import BackgroundImage from "@/components/Homepage/assets/hero-block-bg.jpg";
import BackgroundImageMobile from "@/components/Homepage/assets/hero-block-bg-mobile.png";

const LINKS = [
  {
    label: "category:category-meubels",
    href: {
      en: "/furniture",
      nl: "/meubels",
      de: "/mobel",
      fr: "/meubles",
    },
  },
  {
    label: "category:category-stoelen-fauteuils",
    href: {
      en: "/furniture/chairs-and-lounge-chairs",
      nl: "/meubels/stoelen-fauteuils",
      de: "/mobel/stuhle-und-loungesessel",
      fr: "/meubles/chaises-fauteuils",
    },
  },
  {
    label: "category:category-banken",
    href: {
      en: "/furniture/sofas",
      nl: "/meubels/banken",
      de: "/mobel/sofas",
      fr: "/meubles/canapes",
    },
  },
  {
    label: "category:category-tafels",
    href: {
      en: "/furniture/tables",
      nl: "/meubels/tafels",
      de: "/mobel/tische",
      fr: "/meubles/tables",
    },
  },
  {
    label: "category:category-verlichting",
    href: {
      en: "/lighting",
      nl: "/verlichting",
      de: "/beleuchtung",
      fr: "/luminaires",
    },
  },
];

type HeroBlockProps = {
  className?: string;
};

export const HomepageHeroBlock = ({ className }: HeroBlockProps) => {
  const { t } = useTranslation();
  const { locale } = useRouter();

  const handleCategoryClick = (name: string) => () => {
    /** Trigger `hp_category_navigation` event. */
    analyticsService.trackHpCategoryNavigation(name);
  };

  return (
    <section
      className={cn(
        "relative flex justify-center overflow-hidden p-6 md:min-h-96  md:rounded-[0.375rem]",
        className
      )}
    >
      <Image
        src={BackgroundImage}
        layout="fill"
        objectFit="cover"
        objectPosition="center"
        alt="Background"
        className="hidden md:block"
      />
      <Image
        src={BackgroundImageMobile}
        layout="fill"
        objectFit="cover"
        objectPosition="top"
        alt="Background mobile"
        className="md:hidden"
      />
      <div className="flex flex-col items-center justify-center space-y-4 md:space-y-8">
        <div className="z-10 flex flex-col items-center space-y-2 md:space-y-4">
          <SparkLogo className="hidden h-8 w-8 text-white md:block md:h-10 md:w-20" />
          <Heading as="h1" className="text-center text-white">
            {t("homepage:homepage-hero-block-title-v2")}
          </Heading>
          <Heading as="h3" className="text-center text-white">
            {t("homepage:homeage-hero-block-text")}
          </Heading>
        </div>
        <div className="z-10 flex flex-wrap items-center justify-center space-x-2 space-y-2 [&>:first-child]:mt-2">
          {LINKS.map(link => (
            <LinkButton
              key={link.label}
              href={link.href[locale]}
              size="large"
              color="ghost"
              className="text-center"
              onClick={handleCategoryClick(t(link.label, { lng: "en" }))}
            >
              {t(link.label)}
            </LinkButton>
          ))}
        </div>
      </div>
    </section>
  );
};
